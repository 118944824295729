<template>
  <div>
    <section class="white">
      <v-container>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                CONSULTING <span style="color:#c5c5c5">-|-</span> ANALYSIS <span style="color:#c5c5c5">-|-</span> MANAGEMENT
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                COMPREHENSIVE ADVISORY SERVICES ON THE ENERGY INDUSTRY AND REAL ESTATE MARKET
              </p>
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    ENERGY
                  </v-card-title>
                  <v-card-text>
                    We specialize in providing comprehensive energy consulting services designed to empower businesses and organizations in navigating the complex landscape of the energy sector. Our expertise spans the following core areas:
                    <ul>
                      <li>Renewable Energy Solutions</li>
                      <li>Energy Efficiency Audits</li>
                      <li>Regulatory Compliance and Policy Advisory</li>
                      <li>Innovative Energy Technologies</li>
                      <li>Financial Advisory and Project Financing</li>
                      <li>Comprehensive Services - Energy Trading and Balancing</li>
                      <li>Distribution System Operator Services</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-card
                  outlined
                  tile
                  theme--dark
                  class="mb-5 centered text-justify"
                >
                  <v-card-title>
                    REAL ESTATE
                  </v-card-title>
                  <v-card-text>
                    Our mission is to deliver exceptional real estate consulting services that drive value, innovation, and sustainability for every client we serve. Our expertise encompasses a wide range of services tailored to the dynamic needs of the real estate market:
                    <ul>
                      <li>Market Analysis and Insights</li>
                      <li>Investment Advisory Services</li>
                      <li>Development and Planning</li>
                      <li>Sustainability Consulting (ESG)</li>
                      <li>Portfolio Management</li>
                      <li>Lease Advisory and Tenant Representation</li>
                      <li>Regulatory and Compliance Advisory</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
        <v-row
          class="pb-5 pt-5 background-light-grey"
        >
          <v-col
            cols="10"
            offset="1"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                COMPANIES AND PROJECTS
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                SUPPORTED BY XTERRA<sup>®</sup>
              </p>
            </v-col>
            <v-col cols="12">
              <!-- <div class="mb-2">
                <p class="mb-1">
                  Prezentacja struktury spółki Polgrid SA i projektów w przygotowaniu
                  <a
                    href="/static/PolgridSA.pdf"
                    download
                  >[pobierz]
                  </a>
                  <a
                    href="/static/PolgridSA.pdf"
                    target="_blank"
                  >[zobacz]
                  </a>
                </p>
              </div> -->
            </v-col>
            <v-container class="d-md-inline-flex">
              <v-col
                md="4"
                sm="12"
                class="text-center"
              >
                <v-img src="/static/logo-pg.png" />
              </v-col>
              <v-col
                md="4"
                sm="12"
              >
                <v-img src="/static/ew-logo.png" />
              </v-col>
              <v-col
                md="4"
                sm="12"
              >
                <v-img src="/static/s-logo.png" />
              </v-col>
              <!-- <v-col
                md="3"
                sm="12"
              >
                <v-img src="/static/fm-logo.png" />
              </v-col> -->
            </v-container>
          </v-col>
        </v-row>
        <v-row class="pb-5 pt-5">
          <v-col
            cols="10"
            offset="1"
            style="font-family:'Kanit'!important"
          >
            <v-col
              cols="12"
              max-width="500"
              outlined
              tile
              class="mb-5 centered text-justify pure-border"
            >
              <h2
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
                class="mb-2"
                style="font-family:'Kanit'!important"
              >
                INNOVATIVE INVESTMENT OPPORTUNITIES
              </h2>
              <p
                class="subtitle-1 text-justify mb-1"
                style="font-family:'Kanit'!important"
              >
                BUILDING VALUE BY IMPLEMENTING THE LATEST SOLUTIONS
              </p>
            </v-col>
            <v-container>
              <v-col cols="12">
                <v-card
                  outlined
                  tile
                  class="mb-5 centered text-justify"
                >
                  <v-card-text>
                    We dive deep into the world of renewable energy, energy efficiency, and smart grid technologies to identify and develop investment opportunities that lead the way in innovation. Our expertise allows us to offer projects that not only meet the increasing demand for sustainable energy but also offer a hedge against the volatility of traditional energy markets and high energy prices.
                    Our real estate consulting services focus on integrating green building practices, smart technology, and innovative financing models to create high-value properties that stand the test of time emphasizing sustainable and energy-efficient designs to attract forward-thinking tenants and buyers.
                    Leveraging our profound expertise in the energy and real estate sectors, we offer exceptional investment opportunities and advanced tools for cost optimization and management. Our integrated approach, combining industry insights with analytical tools, allows us to uncover and leverage unique market opportunities. Utilizing innovative technologies and financial strategies, we provide solutions that balance risk and reward, driving sustainable growth and profitability for our clients.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style scoped>
  .pure-border {
    border-left: 3px solid black;
  }
  .background-light-grey {
    background-color: #f1f1f1;
  }
</style>

<script>
  export default {
  }
</script>
